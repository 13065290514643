import React from "react"

// Used to close element on an outside click
const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }
    if (document) {
      document.addEventListener(`mousedown`, listener)
      document.addEventListener(`touchstart`, listener)

      return () => {
        document.removeEventListener(`mousedown`, listener)
        document.removeEventListener(`touchstart`, listener)
      }
    }
  }, [ref, handler])
}

export default useOnClickOutside
